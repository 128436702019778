import React from "react";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Line,
  Legend,
} from "recharts";
import { useMediaQuery } from "react-responsive";
import Title from "./Title";
import "./ProsCons.css";

interface DataItem {
  name: string;
  value: number;
}

interface YearlyDataItem {
  name: string;
  data: DataItem[];
}

interface VoteTaxesProps {
  title: string;
  description: string;
  totalData: DataItem[];
  yearlyData: YearlyDataItem[];
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const VoteTaxes: React.FC<VoteTaxesProps> = ({
  title,
  description,
  totalData,
  yearlyData,
}) => {
  const totalValue = totalData.reduce((total, item) => total + item.value, 0);
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const style = isMobile ? { fontSize: "0.8rem" } : {};
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;
  const CardBodyComponent = isMobile ? "div" : CardBody;
  const CardFooterComponent = isMobile ? "div" : CardFooter;

  const impactOverview = (yearlyData: YearlyDataItem[], isMobile: boolean) => {
    if (!isMobile) {
      return (
        <section>
          <h5>Impact Overview</h5>

          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={yearlyData.map((yearData) => ({
                name: yearData.name,
                ...yearData.data.reduce(
                  (acc, item) => ({ ...acc, [item.name]: item.value }),
                  {}
                ),
              }))}
              margin={{ top: 10, right: 30, left: 10, bottom: 10 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke="#ccc"
              />
              <XAxis
                dataKey="name"
                tick={{ fontSize: 12 }}
                label={{
                  value: "Year",
                  position: "insideBottomRight",
                  offset: -5,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              />
              <YAxis
                tickFormatter={(value) =>
                  typeof value === "number" ? value.toFixed(1) : value
                }
                tick={{ fontSize: 12 }}
                label={{
                  value: "Value in Millions",
                  angle: -90,
                  position: "insideLeft",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              />
              <Tooltip
                formatter={(value) =>
                  typeof value === "number"
                    ? `$${value.toFixed(1)} million`
                    : value
                }
                contentStyle={{
                  fontSize: 12,
                  backgroundColor: "#f5f5f5",
                  borderColor: "#ccc",
                }}
                itemStyle={{ color: "#333" }}
              />
              <Legend wrapperStyle={{ fontSize: 14, fontWeight: "bold" }} />
              {yearlyData[0].data.map((item, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={item.name}
                  stroke={COLORS[index % COLORS.length]}
                  strokeWidth={2}
                  dot={{ r: 4, strokeWidth: 2, fill: "#fff" }}
                  activeDot={{ r: 6 }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </section>
      );
    }

    return null;

    // return (
    //   <section>
    //     <p>Tap a bar to see the year’s detailed breakdown below.</p>
    //     <ResponsiveContainer width="100%" height={300}>
    //       <BarChart
    //         data={yearlyData.map((yearData) => ({
    //           name: yearData.name,
    //           ...yearData.data.reduce(
    //             (acc, item) => ({ ...acc, [item.name]: item.value }),
    //             {}
    //           ),
    //         }))}
    //         margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
    //       >
    //         <CartesianGrid
    //           strokeDasharray="3 3"
    //           vertical={false}
    //           stroke="#ccc"
    //         />
    //         <XAxis dataKey="name" tick={{ fontSize: 10 }} />
    //         <YAxis
    //           tickFormatter={(value) => value.toFixed(1)}
    //           tick={{ fontSize: 10 }}
    //         />
    //         <Tooltip
    //           formatter={(value) => `$${Number(value).toFixed(1)} million`}
    //           contentStyle={{
    //             fontSize: 10,
    //             backgroundColor: "#f5f5f5",
    //             borderColor: "#ccc",
    //           }}
    //           itemStyle={{ color: "#333" }}
    //         />
    //         {yearlyData[0].data.map((item, index) => (
    //           <Bar
    //             key={index}
    //             dataKey={item.name}
    //             fill={COLORS[index % COLORS.length]}
    //           />
    //         ))}
    //       </BarChart>
    //     </ResponsiveContainer>
    //   </section>
    // );
  };

  const renderProsCons = () => (
    <div className="pros-cons-section">
      <h5 className="section-title">Pros & Cons Analysis</h5>
      <Row className="pros-cons-row">
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="pros">
            <h6 className="pros-title">
              Pros <FaRegThumbsUp className="icon" />
            </h6>
            <ul className="pros-list">
              <li>
                Improved Infrastructure: Better maintenance and enhancement of
                public infrastructure.
              </li>
              <li>
                Job Creation: Increased employment opportunities in
                infrastructure projects.
              </li>
              <li>
                Long-Term Economic Growth: Enhanced infrastructure could attract
                businesses, leading to economic growth.
              </li>
              <li>
                Community Safety: Improved road, bridge, and public building
                conditions lead to safer communities.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="cons">
            <h6 className="cons-title">
              Cons <FaRegThumbsDown className="icon" />
            </h6>
            <ul className="cons-list">
              <li>
                Increased Cost of Living: Higher sales tax can increase overall
                expenses for residents, disproportionately affecting low-income
                households.
              </li>
              <li>
                Potential Misallocation: Risk of funds not being used as
                intended due to administrative or governance issues.
              </li>
              <li>
                Negative Impact on Local Businesses: Higher sales tax might
                deter consumers, impacting local businesses' sales.
              </li>
              <li>
                Dependency on Consumption: Sales tax revenue depends on consumer
                spending, which can fluctuate based on economic conditions.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <CardComponent>
      <Title value={title} />
      <CardBodyComponent>
        <h5>The Proposal</h5>

        <section className="mb-4">
          <p className="text-sm">
            This proposition, if approved, will increase the local sales tax
            rate by 0.5% for the next decade. The additional tax revenue,
            estimated at $50 million per year, will be specifically allocated
            towards the maintenance and improvement of public infrastructure in
            our community.
          </p>
        </section>

        <section className="mb-4">
          <h6 className="font-weight-bold mb-2">Proposal Details</h6>
          <ul className="text-sm pl-3">
            <li className="mb-2">
              <strong>Sales Tax Increase:</strong> The local sales tax rate will
              be increased by 0.5% for a period of ten years, starting from
              January 1, 2025, and ending on December 31, 2034.
            </li>
            <li className="mb-2">
              <strong>Use of Funds:</strong> Funds will be used for projects
              such as repairing roads and bridges, upgrading public
              transportation, improving parks and public spaces, and enhancing
              utilities like water and sewage systems.
            </li>
            <li className="mb-2">
              <strong>Oversight:</strong> An annual audit will ensure funds are
              spent as intended, with a public report available.
            </li>
          </ul>
        </section>

        <section>
          <h6 className="font-weight-bold mb-2">Voting Information</h6>
          <p className="text-sm mb-2">
            <strong>A "YES" vote</strong> approves the sales tax increase of
            0.5% for ten years, dedicated to public infrastructure maintenance
            and improvement.
          </p>
          <p className="text-sm">
            <strong>A "NO" vote</strong> opposes the sales tax increase,
            maintaining the current sales tax rate.
          </p>
        </section>

        {impactOverview(yearlyData, isMobile)}

        {renderProsCons()}
      </CardBodyComponent>
      <CardFooterComponent />
    </CardComponent>
  );
};

export default VoteTaxes;
