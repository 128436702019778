import React, {useState} from 'react'
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {FaArrowRight} from 'react-icons/fa'  // Importing a simple, elegant icon from React Icons

const VotePilotPage: React.FC = () =>
{
    const [modalOpen, setModalOpen] = useState(false)

    const toggleModal = () =>
    {
        setModalOpen(!modalOpen)
    }

    return (
        <Container style={{padding: '20px'}}>
            <header className="text-center">
                <h1 className="header-title" style={{fontSize: '36px', fontWeight: '600', color: '#333'}}>
                    VotePilot: Redefining Democracy, One Vote at a Time
                </h1>
                <h2 className="header-subtitle" style={{fontSize: '24px', fontWeight: '300', color: '#777'}}>
                    Welcome to the future of decision-making
                </h2>
            </header>

            <section className="main-section">
                <Row>
                    <Col lg="12" className="text-center">
                        <p style={{fontSize: '18px', lineHeight: '1.6', color: '#555'}}>
                            With VotePilot, creating referendums is as simple as a few taps, and every question is enriched with
                            the context you need for informed decisions. VotePilot uses AI to transform complicated processes
                            into effortless interactions, so your voice can shape the future of your community.
                        </p>
                    </Col>
                </Row>
            </section>

            <section className="main-section">
                <Row>
                    <Col lg="12" className="text-center">
                        <h3 style={{fontSize: '28px', fontWeight: '500', color: '#333'}}>Built for Today, Evolving for Tomorrow</h3>
                        <p style={{fontSize: '18px', lineHeight: '1.6', color: '#555'}}>
                            Our vision goes beyond just AI. Today, VotePilot brings clarity to every question, but the future promises
                            even more. Soon, robust mechanisms like blockchain will ensure every vote is secure, transparent, and
                            unchangeable. This is democracy, elevated by technology—where every decision you make will leave an enduring mark.
                        </p>
                    </Col>
                </Row>
            </section>

            <section className="main-section">
                <Row>
                    <Col lg="12" className="text-center">
                        <h3 style={{fontSize: '28px', fontWeight: '500', color: '#333'}}>Effortless Participation</h3>
                        <p style={{fontSize: '18px', lineHeight: '1.6', color: '#555'}}>
                            Your experience is designed to be seamless, intuitive, and beautiful. Whether it’s inviting participants,
                            casting a vote, or engaging with the process, it’s all about ease. The interface reflects the simplicity of direct democracy and brings people together without barriers.
                        </p>
                    </Col>
                </Row>
            </section>

            <section className="cta-section text-center">
                <h2 style={{fontSize: '32px', fontWeight: '600', color: '#333'}}>Join Us and Shape the Future</h2>
                <p style={{fontSize: '18px', lineHeight: '1.6', color: '#555', marginBottom: '20px'}}>
                    We’re just getting started. With VotePilot, you’re not just voting for the sake of voting. You’re joining a movement that values every voice,
                    and your input has a real impact. Try the app today and experience democracy the way it was meant to be—simple, elegant, and powerful.
                </p>
                <Button color="primary" size="lg" style={{padding: '12px 24px', fontSize: '18px', fontWeight: 'bold'}} onClick={toggleModal}>
                    See How It Works <FaArrowRight style={{marginLeft: '8px'}} />
                </Button>
            </section>

            {/* Modal for informing users about App Store availability */}
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Become a Beta Tester for VotePilot</ModalHeader>
                <ModalBody>
                    We are actively developing VotePilot and would love to have your feedback as we continue to refine the app. Whether it’s before or after the official launch, your insights will help shape the future of decision-making.
                    <br /><br />
                    If you're interested in trying out the app and being part of our testing team, contact us to become a beta tester.
                    <br /><br />
                    Reach out to us at <a href="mailto:contact@directdemocracy.global"><strong>contact@directdemocracy.global</strong></a> to join the beta testing team. Once you're on board, you'll get early access through Apple's TestFlight platform and help improve the app at every stage.
                    <br /><br />
                    Be a part of something meaningful. We look forward to hearing from you!
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}

export default VotePilotPage