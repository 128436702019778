import React, {useState} from 'react'
import {Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem} from 'reactstrap'
import {Link, useLocation} from 'react-router-dom'
import './NavMenu.css'
import {AppPaths} from '../AppPaths'

const CustomLink: React.FC<{to: string, children: React.ReactNode}> = ({to, children}) =>
{
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link className={`nav-link ${isActive ? 'active' : ''}`} to={to}>
      {children}
    </Link>
  )
}

const useIsEducationActive = () =>
{
  const location = useLocation()
  const educationPaths = [AppPaths.ReferendumListPage, AppPaths.TaxInsights, AppPaths.EducationInsights, AppPaths.HealthcareInsights,
  AppPaths.EnvironmentInsights, AppPaths.HousingInsights, AppPaths.ForeignPolicyInsights]
  return educationPaths.includes(location.pathname)
}

const useIsPrototypeActive = () =>
{
  const location = useLocation()
  const prototypePaths = [AppPaths.ReferendumPage, AppPaths.TaxReferendum, AppPaths.EducationReferendum, AppPaths.HealthcareReferendum,
  AppPaths.VoteHistory, AppPaths.EnvironmentReferendum, AppPaths.HousingReferendum, AppPaths.ForeignPolicyReferendum]
  return prototypePaths.includes(location.pathname)
}

export const NavMenu: React.FC = () =>
{
  const [collapsed, setCollapsed] = useState(true)

  const toggleNavbar = () =>
  {
    setCollapsed(!collapsed)
  }

  const isEducationActive = useIsEducationActive()
  const isPrototypeActive = useIsPrototypeActive()

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-1" container light>
        <NavbarBrand tag={Link} to="/">
          Direct Democracy Corporation
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <CustomLink to={AppPaths.Home}>Home</CustomLink>
            </NavItem>
            <NavItem>
              <CustomLink to={AppPaths.VotePilot}>Vote Pilot</CustomLink>
            </NavItem>
            <NavItem>
              <Link className={`nav-link ${isPrototypeActive || isEducationActive ? 'active' : ''}`} to={AppPaths.ReferendumPage}>
                Prototype
              </Link>
            </NavItem>
            <NavItem>
              <CustomLink to={AppPaths.ContactUs}>About Us</CustomLink>
            </NavItem>

          </ul>
        </Collapse>
      </Navbar>
    </header>
  )
}
