import React from 'react';
import ReferendumForm from './ReferendumForm';
import VoteDescription from './VoteDescription';
import { Card, CardBody, CardFooter, CardHeader, CardSubtitle } from 'reactstrap';

const EducationReferendum = () => {
  const paragraphs: string[] = [
    "A proposal has been made to reallocate 2% of the administrative budget, approximately $13.8 billion, towards enhancing educational resources and increasing teacher salaries. This would result in an estimated increase of $143 per teacher salary and an equivalent increase in the per student budget for educational resources.",
    'Do you approve of reallocating 2% of the administrative budget to equally increase teacher salaries and enhance educational resources per student?',
  ];

  return <ReferendumForm
    referendum="Education Referendum"
    voteDescription={
      <Card className="mb-3">
        <CardHeader tag="h5">
          Proposition 138: Enhance Educational Resources and Increase Teacher Salaries
        </CardHeader>
        <CardBody>

          <p>
            A proposal has been made to reallocate 2% of the administrative budget,
            approximately $13.8 billion, towards enhancing educational resources and
            increasing teacher salaries. This would result in an estimated increase of
            $143 per teacher salary and an equivalent increase in the per student budget
            for educational resources.
          </p>
          <h6>Proposal:</h6>
          <ul>
            <li>
              <strong>Enactment of a Reallocation: </strong>
              The 2% reallocation of the administrative budget will take effect on
              January 1, 2024.
            </li>
            <li>
              <strong>Dedication of Revenues: </strong>
              All additional revenues generated by the reallocation will be expressly
              earmarked for the enhancement of educational resources and the increase
              of teacher salaries.
            </li>
            <li>
              <strong>Use of Funds: </strong>
              Funds collected from the reallocation will be used to:
              <ul>&bull; Increase teacher salaries by $143 per teacher.</ul>
              <ul>&bull; Increase the per student budget for educational resources by an equivalent amount.</ul>
              <ul>&bull; Support other projects that enhance educational resources and increase teacher salaries.</ul>
            </li>
            <li>
              <strong>Oversight and Transparency: </strong>
              An annual audit will be conducted to ensure that all revenues are spent
              as intended, and a report on the use of funds will be made publicly
              available.
            </li>
          </ul>
          <h6>Voting:</h6>
          <p>
            <strong>A "YES" vote</strong> approves the reallocation of 2% of the
            administrative budget to equally increase teacher salaries and enhance
            educational resources per student.
          </p>
          <p>
            <strong>A "NO" vote</strong> opposes the reallocation of 2% of the
            administrative budget, maintaining the current allocation.
          </p>
        </CardBody>
        <CardFooter />
      </Card>
    }
  />
};

export default EducationReferendum;
