import { Container, Row, Col } from 'reactstrap';
import { NavMenu } from './NavMenu';

export const Layout = (props: any) => {
  const year = new Date().getFullYear();

  return (
    <div className="d-flex flex-column min-vh-100">
      <NavMenu />
      <Container className="flex-grow-1 mt-0">
        <Row>
          <Col sm="12" md={{ size: 10, offset: 1 }}>
            {props.children}
          </Col>
        </Row>
      </Container>
      <footer className="mt-auto text-center p-3 bg-light border-top">
        <p className="mb-0">&copy; {year} Direct Democracy Corporation. All rights reserved.</p>
      </footer>
    </div>
  );
}
