import React from 'react'
import {Container, Row, Col, Button} from 'reactstrap'
import {Link} from 'react-router-dom'

export const SupportPage: React.FC = () =>
{
    const supportEmail = 'contact@directdemocracy.global' // Add your email here

    return (
        <Container className="support-page">
            <Row>
                <Col>
                    <h1>Support</h1>
                    <p>
                        Need help or have questions? Reach out to us via email and we’ll assist you as soon as possible.
                    </p>
                    <p>
                        <strong>Contact us at: </strong>
                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                    </p>
                    <Link to="/">
                        <Button color="primary">Back to Home</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default SupportPage