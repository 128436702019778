import React from "react"
import {Container, Row, Col} from "reactstrap"
import {FaPhoneAlt, FaMapMarkerAlt} from "react-icons/fa"
import patrickImage from "../images/patrick.jpg"
import mickeyImage from "../images/mickey.jpg"
import {useMediaQuery} from "react-responsive"

const VentureSection: React.FC = () =>
{
  const isMobile = useMediaQuery({query: "(max-width: 1000px)"})

  return (
    <Row className="justify-content-center" style={{marginBottom: 30, marginTop: -20}}>
      <Col md={8} className="text-center">
        <h2 style={{marginBottom: "20px", fontWeight: "600", fontSize: isMobile ? "28px" : "36px"}}>Our Story</h2>
        <p style={{marginBottom: "40px", fontSize: "18px", lineHeight: "1.8", maxWidth: "800px", margin: "auto"}}>
          This venture began as a result of countless conversations. Patrick and Mickey have shared a strong friendship and vision for many decades.
          Their enduring bond and common goals have inspired them to collaborate on this initiative.
          Thanks to the concerted efforts of our highly skilled team, we are confident in our ability to achieve our product development milestones by mid-2025.
        </p>
      </Col>
    </Row>
  )
}

const PhoneSection: React.FC = () =>
{
  return (
    <div style={{textAlign: "center", color: "#1D1D1F"}}>
      <h4 style={{marginBottom: "15px", fontWeight: "600"}}>Engage With Us</h4>
      <p style={{marginBottom: "20px", fontSize: "18px", lineHeight: "1.6"}}>
        No matter where you are in the world, we're here to talk. Drop us a line anytime.
      </p>
      <a
        href="tel:+13232070704"
        style={{
          fontSize: "20px",
          display: "inline-flex",
          alignItems: "center",
          color: "#007AFF",
          textDecoration: "none",
          transition: "color 0.3s",
        }}
        onMouseOver={(e) => (e.currentTarget.style.color = "#0056b3")}
        onMouseOut={(e) => (e.currentTarget.style.color = "#007AFF")}
      >
        <FaPhoneAlt style={{marginRight: "10px", fontSize: "20px"}} />
        +1 (323) 633-0903
      </a>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FaMapMarkerAlt style={{marginRight: "8px", color: "#007AFF"}} />
        <p style={{margin: 0, fontSize: "16px"}}>225 S Lake Ave Ste 300, Pasadena, CA 91101</p>
      </div>
    </div>
  )
}

export const ContactUs: React.FC = () =>
{
  const isMobile = useMediaQuery({query: "(max-width: 1000px)"})

  return (
    <Container style={{color: "#1D1D1F", fontFamily: "San Francisco, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif"}}>
      <VentureSection />

      <Row className="justify-content-center" style={{marginBottom: -20}}>
        <Col md={isMobile ? 12 : 5} className="d-flex flex-column align-items-center text-center mb-5">
          <img
            src={patrickImage}
            alt="Patrick McPhilamy (CEO)"
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "15px",
              border: "2px solid #D1D1D6",
            }}
          />
          <h5 style={{fontWeight: "600"}}>Patrick McPhilamy (CEO)</h5>
          <p style={{fontSize: "16px", marginBottom: "10px"}}>
            <a href="mailto:patrick.mcphilamy@directdemocracy.global" style={{color: "#007AFF", textDecoration: "none"}}>patrick.mcphilamy@directdemocracy.global</a>
          </p>
          <p className="flex-grow-1" style={{maxWidth: "400px", fontSize: "16px", lineHeight: "1.6"}}>
            Patrick stands as the cohesive force of our venture. He adeptly connects divergent facets with remarkable character strength that enables us to remain focused amidst the varying creative directions.
            Exuding calm and optimism when faced with challenges, he becomes our rock of stability. Furthermore, his grasp of political dynamics and extensive legal insights form an essential cornerstone of our corporation.
          </p>
        </Col>

        <Col md={isMobile ? 12 : 5} className="d-flex flex-column align-items-center text-center mb-5">
          <img
            src={mickeyImage}
            alt="Mickey Maravic (CMO)"
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "15px",
              border: "2px solid #D1D1D6",
            }}
          />
          <h5 style={{fontWeight: "600"}}>Mickey  Maravic (CMO)</h5>
          <p style={{fontSize: "16px", marginBottom: "10px"}}>
            <a href="mailto:mickey.maravic@directdemocracy.global" style={{color: "#007AFF", textDecoration: "none"}}>mickey.maravic@directdemocracy.global</a>
          </p>
          <p className="flex-grow-1" style={{maxWidth: "400px", fontSize: "16px", lineHeight: "1.6"}}>
            Mickey, the orchestrator of our collaborative effort, brilliantly morphed what began as a modest academic paper into a dynamic initiative that fully engages our commitment.
            With extensive experience in various ventures over the decades, including leading a successful catering company, Mickey’s spirit of resourceful innovation remains a steadfast source of our inspiration and drive.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h4 style={{marginBottom: "15px", fontWeight: "600"}}>Technology Team</h4>
          <p style={{marginBottom: "20px", fontSize: "18px", lineHeight: "1.6"}}>
            We have established a dedicated technology team at Direct Democracy Corporation. Leading our efforts as the software architect
            and chief designer is Robert Karapetyan.
            Our team also includes researchers from prestigious institutions such as Carnegie Mellon and Georgia Tech.
            This blend of industry expertise and academic insight fortifies our strategic development and innovation capabilities.
          </p>
          <p style={{marginBottom: "20px", fontSize: "18px", lineHeight: "1.6"}}>
            If you have any questions about the prototype or the application currently in development, please feel free to get in touch.
          </p>
          <p style={{fontSize: "16px"}}>
            <a href="mailto:robert.karapetyan@directdemocracy.global" style={{color: "#007AFF", textDecoration: "none"}}>robert@directdemocracy.global</a>
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center mt-3">
        <Col md={12}>
          <PhoneSection />
        </Col>
      </Row>
    </Container>
  )
}