import React from 'react';
import ReferendumForm from './ReferendumForm';
import VoteDescription from './VoteDescription';
import { Card, CardBody, CardFooter, CardHeader, CardText, CardTitle } from 'reactstrap';

const HealthcareReferendum = () => {
  const paragraphs: string[] = [
    "Do you support the 'Affordable Medication Act' which allows for direct negotiation of drug prices with pharmaceutical companies, permits safe importation of prescription drugs, implements a cap on out-of-pocket drug costs, mandates transparency in drug pricing, and regulates annual price increases on medications, all with the aim of making prescription medications more affordable and accessible?",
  ];

  return <ReferendumForm
    referendum="Healthcare Referendum"
    voteDescription={
      <Card className="mb-3">
        <CardHeader tag="h5">Proposition 139: Affordable Medication Act</CardHeader>
        <CardBody>   
          <CardText>
            The Affordable Medication Act is a proposed law that seeks to reform current practices regarding prescription medication pricing. If passed, this proposition will:
          </CardText>
          <ul>
            <li>Empower the relevant authorities to directly negotiate drug prices with pharmaceutical companies, thereby potentially lowering the cost of medications.</li>
            <li>Allow for the safe and regulated importation of prescription drugs from approved foreign sources, thus introducing more competition into the market.</li>
            <li>Implement a cap on out-of-pocket drug costs for patients, alleviating the financial strain faced by individuals in need of essential medications.</li>
            <li>Require full transparency in drug pricing, compelling pharmaceutical companies to disclose their pricing strategies and justifications for pricing decisions.</li>
            <li>Regulate the annual increase in drug prices to prevent undue price hikes.</li>
          </ul>
          <CardText>
            This proposed law aims to make prescription medications more affordable and accessible for all citizens.
          </CardText>
          <CardText>
            Shall the Affordable Medication Act be adopted?
          </CardText>
          <CardText>
          <strong>A "YES" vote</strong> supports permitting the state to negotiate drug prices directly with pharmaceutical companies, authorizing the safe importation of drugs, implementing a cap on out-of-pocket drug costs, requiring pricing transparency, and controlling annual price increases.
          </CardText>
          <CardText>
          <strong>A "No" vote</strong> opposes these provisions and maintains the current system for prescription medication pricing and distribution.
          </CardText>
        </CardBody>
        <CardFooter />
      </Card>
    }
  />
};

export default HealthcareReferendum;
