import React, { useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AppPaths } from '../../AppPaths';
import { useMediaQuery } from 'react-responsive';

import infra from '../../images/infra.jpg';
import education from '../../images/education.jpg'
import healthcare from '../../images/healthcare.jpg'
import housing from '../../images/housing.jpg'
import environment from '../../images/environment.jpg'
import foreignpolicy from '../../images/foreignpolicy.jpg'

interface Referendum {
  title: string;
  description: string;
  imageSrc: string;
  path: string;
  learnMorePath: string;
}

const ReferendumPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });

  const [loaded, setLoaded] = useState<{ [key: string]: boolean }>({});

  const referendums: Referendum[] = [
    {
      title: 'Infrastructure Referendum',
      description: 'Vote on a proposed tax reform that aims to fund public infrastructure improvements and social welfare programs.',
      imageSrc: infra,
      path: AppPaths.TaxReferendum,
      learnMorePath: AppPaths.TaxInsights
    },
    {
      title: 'Education Referendum',
      description: 'Support or reject a proposal to increase funding for public education, including enhancing educational resources and teacher salaries.',
      imageSrc: education,
      path: AppPaths.EducationReferendum,
      learnMorePath: AppPaths.EducationInsights
    },
    {
      title: 'Healthcare Referendum',
      description: 'Cast your vote on a proposal designed to overhaul prescription drug policy, addressing price negotiations, drug importation, cost caps, pricing transparency, and annual price increase control.',
      imageSrc: healthcare,
      path: AppPaths.HealthcareReferendum,
      learnMorePath: AppPaths.HealthcareInsights
    },
    {
      title: 'Environment Referendum',
      description: 'Vote on the proposed Private Property Environmental Protection Act, aiming to safeguard individual properties from pollution and promote a healthier environment.',
      imageSrc: environment,
      path: AppPaths.EnvironmentReferendum,
      learnMorePath: AppPaths.EnvironmentInsights
    },
    {
      title: 'Housing Referendum',
      description: 'Vote to support or reject the Shelter or Accountability proposal aimed at addressing homelessness through offering relocation to designated communities.',
      imageSrc: housing,
      path: AppPaths.HousingReferendum,
      learnMorePath: AppPaths.HousingInsights
    },
    {
      title: 'Foreign Policy Referendum',
      description: 'Vote on a proposed military operation in a neighboring country, a move aimed at strengthening our national security but potentially challenging international law and relationships.',
      imageSrc: foreignpolicy,
      path: AppPaths.ForeignPolicyReferendum,
      learnMorePath: AppPaths.ForeignPolicyInsights
    },
  ];

  const handleImageLoad = (index: number) => {
    setLoaded(prevState => ({ ...prevState, [index]: true }));
  };


  const renderReferendumOptions = () => {
    return referendums.map((referendum, index) => (
      <Col sm="12" md="6" lg="4" className="mb-4" key={index}>
        <Card className="h-100">
          {!isMobile && (
            <>
              {!loaded[index] &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                  <Spinner color="primary" />
                </div>
              }
              <CardImg
                top
                width="100%"
                src={referendum.imageSrc}
                alt={referendum.title}
                style={{ height: '200px', objectFit: 'cover', display: loaded[index] ? 'block' : 'none' }}
                onLoad={() => handleImageLoad(index)}
              />
            </>
          )}
          <CardBody className="d-flex flex-column justify-content-between">
            <div>
              <CardTitle tag="h5">{referendum.title}</CardTitle>
              <CardText>{referendum.description}</CardText>
            </div>
            <div className="d-flex flex-column">
              <Button color="primary" tag={Link} to={referendum.path} style={{ marginTop: 15 }}>
                Vote Now
              </Button>
              <Button color="secondary" tag={Link} to={referendum.learnMorePath} style={{ marginTop: 15 }}>
                Learn More
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    ));
  };

  const renderReferendumOptionsMobile = () => {
    return referendums.map((referendum, index) => (
      <Col sm="12" md="6" lg="4" className="mb-4" key={index}>
        <Card className="h-100">
          <CardBody className="d-flex flex-column justify-content-between">
            <div className="d-flex align-items-center" style={{ marginBottom: 10 }}>
              {!loaded[index] &&
                <div style={{ width: '75px', height: '75px', marginRight: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spinner color="primary" />
                </div>
              }
              <CardImg
                src={referendum.imageSrc}
                alt={referendum.title}
                style={{
                  width: '75px',
                  height: '75px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  marginRight: '10px',
                  display: loaded[index] ? 'block' : 'none',
                  border: loaded[index] ? '1px solid #c0c0c0' : 'none'
                }}
                onLoad={() => handleImageLoad(index)}
              />
              <CardTitle tag="h5">{referendum.title}</CardTitle>
            </div>
            <CardText>{referendum.description}</CardText>
            <div className="d-flex flex-column">
              <Button color="primary" tag={Link} to={referendum.path} style={{ marginTop: 10, fontSize: '0.9rem' }}>
                Vote Now
              </Button>
              <Button color="secondary" style={{ marginTop: 10, fontSize: '0.9rem' }} tag={Link} to={referendum.learnMorePath}>
                Learn More
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    ));
  };


  return (
    <Container>
      <Row>{isMobile ? renderReferendumOptionsMobile() : renderReferendumOptions()}</Row>
      <div className="mb-4">
        <h5>Vote History</h5>
        <p>View your previously made votes on all referendums that you have voted on.</p>
        <Button
          color="warning"
          tag={Link}
          to="/vote-history"
          style={{
            backgroundColor: '#f0ad4e',
            color: '#fff',
            borderColor: '#f0ad4e',
          }}
          activestyle={{
            backgroundColor: '#d58512',
            borderColor: '#d58512',
          }}
          className="mb-3"
          block
        >
          View Vote History
        </Button>
      </div>
    </Container>
  );
};

export default ReferendumPage;
