import React, { useState, FC } from "react";
import { AppPaths } from "../AppPaths";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  Collapse,
  Button,
  Container,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import "./Home.css";
import logo from "../images/Logo.jpg";
import { FaArrowRight, FaCaretDown, FaCaretUp } from "react-icons/fa";

export const Home: FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean[]>([]);

  const toggle = (id: number) => {
    const newIsOpen = [...isOpen];
    newIsOpen[id] = !newIsOpen[id];
    setIsOpen(newIsOpen);
  };

  const handleGetStarted = () => {
    navigate(AppPaths.ContactUs);
  };

  const handleCheckOutPrototype = () => {
    navigate(AppPaths.ReferendumPage);
  };

  const mobileLayout = (
    <Container style={{ padding: "20px" }}>
      <header className="text-center">
        <img
          src={logo}
          alt="Company Logo"
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          className="mb-3"
        />
        <h1 className="header-title">
          Welcome to Direct Democracy Corporation
        </h1>
        <h2 className="header-subtitle">Democracy at Your Fingertips</h2>
      </header>

      <section className="main-section">
        <div className="row">
          <div className="col-lg-6 col-md-12 text-center">
            <h3>Citizen Empowerment</h3>
            <p>
              We invite you to explore a new era of civic engagement. Discover
              how Direct Democracy Corporation enables your voice to shape the
              future.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 text-center">
            <h3>Prototype Preview</h3>
            <p>
              Experience the power of direct democracy through our prototype.
              Click below to begin.
            </p>
            <Button
              className="btn btn-primary"
              style={{
                padding: "10px 20px",
                fontWeight: "bold",
                width: "100%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onClick={handleCheckOutPrototype}
            >
              Try Our Prototype <FaArrowRight />
            </Button>
          </div>
        </div>
      </section>

      <section>
        <Row className="justify-content-center text-center">
          <Col xs="12">
            <h3>Join the Movement</h3>
            <p>
              Direct Democracy Corporation is more than a platform; it's a
              movement. Stand up, be heard, and help shape a future where every
              voice matters.
            </p>
            <Button
              className="btn btn-primary"
              style={{
                padding: "10px 20px",
                fontWeight: "bold",
                width: "100%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onClick={handleGetStarted}
            >
              Your Voice Matters <FaArrowRight />
            </Button>
          </Col>
        </Row>
      </section>

      <section style={{ marginTop: 50 }}>
        <Card>
          <CardHeader tag="h3" className="text-center">
            Cutting-Edge Democracy
          </CardHeader>
          <CardBody>
            {[
              {
                title: "P2P",
                content:
                  "Our platform uses peer-to-peer technology to revolutionize democratic processes. Your votes impact real issues, stored securely across thousands of devices.",
              },
              {
                title: "Blockchain",
                content:
                  "Blockchain ensures each vote is permanently recorded and immutable, guaranteeing trust and transparency in the democratic process.",
              },
              {
                title: "Public Key Cryptography",
                content:
                  "We prioritize privacy and security with public key cryptography. Vote with confidence knowing your identity and data are protected.",
              },
            ].map((item, index) => (
              <Row className="justify-content-center text-center" key={index}>
                <Col xs="12">
                  <div style={{ padding: "10px", borderRadius: "5px" }}>
                    <h5>{item.title}</h5>
                  </div>
                  <Button
                    color="btn btn-primary"
                    onClick={() => toggle(index)}
                    style={{
                      marginBottom: "1rem",
                      width: "90%",
                      fontSize: "14px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {isOpen[index] ? <FaCaretUp /> : <FaCaretDown />}{" "}
                    {isOpen[index] ? "Hide details" : "Show details"}
                  </Button>
                  <Collapse isOpen={isOpen[index]}>
                    <p style={{ color: "#555" }}>{item.content}</p>
                  </Collapse>
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>
      </section>
    </Container>
  );

  return isMobile ? (
    mobileLayout
  ) : (
    <div className="container">
      <header className="text-center">
        <img
          src={logo}
          alt="Company Logo"
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          className="mb-3"
        />
        <h1 className="header-title">
          Welcome to Direct Democracy Corporation
        </h1>
        <h2 className="header-subtitle">Democracy at Your Fingertips</h2>
      </header>

      <section className="main-section">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <h3>Citizen Empowerment</h3>
            <p>
              As we stand at the crossroads of technological advancement and
              democratic evolution, we invite you to a new era of civic
              engagement. With Direct Democracy Corporation, your voice becomes
              a powerful force in shaping the world around you.
            </p>
          </div>
          <div className="col-lg-6 col-md-12">
            <h3>Peer-to-Peer Innovation</h3>
            <p>
              Embrace the future of democracy with our peer-to-peer platform.
              Here, your votes transcend traditional boundaries and impact real
              issues. Our decentralized approach ensures your voice is heard,
              safeguarded, and unaltered.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <h3>Blockchain Transparency</h3>
            <p>
              Trust is the cornerstone of our system. Utilizing blockchain
              technology, each vote is permanently recorded and immutable. This
              guarantees the sanctity of your voice and promotes a transparent,
              trustworthy democratic process.
            </p>
          </div>
          <div className="col-lg-6 col-md-12">
            <h3>Cryptographic Security</h3>
            <p>
              Your privacy and security are paramount. Our platform employs
              cutting-edge public key cryptography to authenticate and protect
              your identity. Vote with confidence, knowing your information is
              secure and your participation seamless.
            </p>
          </div>
        </div>
      </section>

      <section className="cta-section text-center">
        <h2>Join Us in Shaping the Future</h2>
        <p>
          Direct Democracy Corporation is more than a platform; it's a movement.
          Engage with us to build a future where every opinion counts. Together,
          we can create a society where every voice is valued. Explore our
          prototype and see how direct democracy works.
        </p>
        <button className="btn btn-primary" onClick={handleCheckOutPrototype}>
          See How It Works
        </button>
      </section>
    </div>
  );
};
