import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as BarTooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  RadarChart,
  PolarGrid,
  PolarRadiusAxis,
  PolarAngleAxis,
  Radar,
} from "recharts";
import { Card, CardBody, Row, Col, CardFooter, CardHeader } from "reactstrap";
import { FaRegThumbsDown, FaRegThumbsUp, FaSearchPlus } from "react-icons/fa";
import Title from "./Title";
import {
  budgetDataEducation,
  resourceDataEducation,
  COLORSEducation,
} from "./VoteEducationData";
import { useMediaQuery } from "react-responsive";
import "./ProsCons.css";
import "./Diagram.css";

import educationdiagram from "../../images/educationdiagram.jpg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const VoteEducationVersion1 = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const isMobileExtraSmall = useMediaQuery({ query: "(max-width: 480px)" });
  const style = isMobile ? { fontSize: "0.8rem" } : {};
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;
  const CardBodyComponent = isMobile ? "div" : CardBody;
  const CardFooterComponent = isMobile ? "div" : CardFooter;
  const renderLabel = (entry: any) => `${(entry.percent * 100).toFixed(0)}%`;
  const [isOpen, setIsOpen] = useState(false);

  const impactOverview = (isMobile: boolean) => {
    return isMobile ? (
      <ResponsiveContainer width="100%" height={isMobileExtraSmall ? 400 : 330}>
        <PieChart>
          <Pie
            data={resourceDataEducation}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label={renderLabel}
          >
            {resourceDataEducation.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORSEducation[index % COLORSEducation.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `${value}%`} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    ) : (
      <ResponsiveContainer width="100%" height={300}>
        <RadarChart outerRadius={90} data={resourceDataEducation}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis />
          <Radar
            name="Resources"
            dataKey="value"
            stroke="#8884d8"
            fill="#3f51b5"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    );
  };

  const renderProsCons = () => (
    <div className="pros-cons-section">
      <h5 className="section-title">Pros & Cons Analysis</h5>
      <Row className="pros-cons-row">
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="pros">
            <h6 className="pros-title">
              Pros <FaRegThumbsUp className="icon" />
            </h6>
            <ul className="pros-list">
              <li>
                Improved educational resources: Enhanced materials, technology,
                and facilities for better education.
              </li>
              <li>
                Higher teacher salaries: Increased financial recognition and
                incentive for educators.
              </li>
              <li>
                Motivated teachers: Boosted morale and dedication for better
                student outcomes.
              </li>
              <li>
                Reduced administrative costs: Streamlined operations for
                efficient resource allocation.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="cons">
            <h6 className="cons-title">
              Cons <FaRegThumbsDown className="icon" />
            </h6>
            <ul className="cons-list">
              <li>
                Administrative impact: Potential reduction in administrative
                support services.
              </li>
              <li>
                Budget constraints: Other areas of education may face limited
                funding.
              </li>
              <li>
                Equity concerns: Uniform reallocation may not address existing
                disparities.
              </li>
              <li>
                Long-term sustainability: Uncertainty about maintaining
                increased funding levels.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <CardComponent>
      <Title value={"Public Education Funding"} />
      <CardBodyComponent>
        <h5>The Proposal</h5>
        <section className="mb-4">
          <p className="text-sm">
            The proposed referendum seeks to redistribute 2% of the
            administrative budget, totaling around $13.8 billion, to enhance
            educational resources and increase teachers' salaries. This
            strategic reallocation is anticipated to raise each teacher's pay by
            approximately $143 and provide a similar increase in the per-student
            allocation for educational resources.
          </p>
        </section>

        <section className="mb-4">
          <h6 className="font-weight-bold mb-2">Voting Information</h6>
          <p className="text-sm mb-2">
            <strong>A "YES" vote</strong> approves the redistribution of 2% of
            the administrative budget to increase educational resources and
            teachers' salaries.
          </p>
          <p className="text-sm">
            <strong>A "NO" vote</strong> opposes the redistribution, maintaining
            the current budget allocation.
          </p>
        </section>

        <section>
          <h5>Reallocation of Funds: Administrative to Education Budget</h5>
          <p>
            The following bar chart illustrates the reallocation of funds from
            the administrative budget to the education budget:
          </p>
          <section className="mb-4">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={budgetDataEducation}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(tick) => `$${tick}B`} />
                <BarTooltip formatter={(value) => `$${value}B`} />
                <Legend />
                <Bar
                  dataKey="initialBudget"
                  fill={COLORSEducation[0]}
                  name="Initial Budget"
                />
                <Bar
                  dataKey="newBudget"
                  fill={COLORSEducation[1]}
                  name="New Budget"
                />
              </BarChart>
            </ResponsiveContainer>
          </section>{" "}
        </section>

        {isMobile ? (
          <section>
            <section className="mb-4">
              <div className="header-with-icon mb-2">
                <h5 className="font-weight-bold">Impact Diagram</h5>
                <div className="tooltip-container">
                  <FaSearchPlus
                    className="header-icon"
                    onClick={() => setIsOpen(true)}
                  />
                  <span className="tooltip-text">Click to enlarge</span>
                </div>
              </div>
              <p className="text-sm mb-2">
                The following diagram illustrates the potential impact of
                different budget allocation strategies on the overall education
                funding. The diagram shows the outcomes of redistributing or
                maintaining the budget:
              </p>

              <div className="diagram-container">
                <button
                  className="view-diagram-button"
                  onClick={() => setIsOpen(true)}
                >
                  View Diagram
                </button>
              </div>
              {isOpen && (
                <Lightbox
                  mainSrc={educationdiagram}
                  onCloseRequest={() => setIsOpen(false)}
                />
              )}
            </section>
          </section>
        ) : (
          <section className="mb-4">
            <div className="header-with-icon mb-2">
              <h5 className="font-weight-bold">Impact Diagram</h5>
              <div className="tooltip-container">
                <FaSearchPlus
                  className="header-icon"
                  onClick={() => setIsOpen(true)}
                />
                <span className="tooltip-text">Click to enlarge</span>
              </div>
            </div>
            <p className="text-sm mb-2">
              The following diagram illustrates the potential impact of
              different budget allocation strategies on the overall education
              funding. The diagram shows the outcomes of redistributing or
              maintaining the budget:
            </p>

            <div className="diagram-container" onClick={() => setIsOpen(true)}>
              <img
                src={educationdiagram}
                alt="Education Budget Diagram"
                className="responsive-image"
                style={{ cursor: "pointer" }}
              />
              <div className="overlay-text">Click to enlarge</div>
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={educationdiagram}
                onCloseRequest={() => setIsOpen(false)}
              />
            )}
          </section>
        )}

        {isMobile ? null : (
          <section className="mb-4">
            <h5 className="font-weight-bold mb-2">Resource Distribution</h5>
            <p className="text-sm">
              The chart below represents a suggested distribution of the $143
              per student for various educational resources. For example, a
              larger portion of this amount may go towards 'Textbooks &
              Workbooks' and 'Digital Resources', as compared to other
              resources. The actual distribution may vary based on the specific
              needs of each school and student.
            </p>
            {impactOverview(isMobile)}
          </section>
        )}

        {renderProsCons()}
      </CardBodyComponent>
      <CardFooterComponent />
    </CardComponent>
  );
};

export default VoteEducationVersion1;
