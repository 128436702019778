import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardFooter,
  Col,
  Row,
} from "reactstrap";
import { FaRegThumbsUp, FaRegThumbsDown, FaSearchPlus } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import Title from "./Title";
import "./ProsCons.css";
import healthcarediagram from "../../images/healthcarediagram.jpg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./Diagram.css";

interface DataItem {
  name: string;
  currentPrice: number;
  projectedPrice: number;
}

interface VoteHealthCareProps {
  title: string;
  description: string;
  data: DataItem[];
}

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value } = props;
  const radius = 10;

  return (
    <text
      x={x + width / 2}
      y={y - radius}
      fill="#8884d8"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {`$${value}`}
    </text>
  );
};

const VoteHealthCare: React.FC<VoteHealthCareProps> = ({
  title,
  description,
  data,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;
  const CardBodyComponent = isMobile ? "div" : CardBody;
  const CardFooterComponent = isMobile ? "div" : CardFooter;
  const [isOpen, setIsOpen] = useState(false);

  const renderProsCons = () => (
    <div className="pros-cons-section">
      <h5 className="section-title">Pros & Cons Analysis</h5>
      <Row className="pros-cons-row">
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="pros">
            <h6 className="pros-title">
              Pros <FaRegThumbsUp className="icon" />
            </h6>
            <ul className="pros-list">
              <li>May significantly reduce drug prices.</li>
              <li>
                Could increase accessibility to medications for low-income
                individuals.
              </li>
              <li>
                May promote transparency and accountability in the
                pharmaceutical industry.
              </li>
              <li>
                Potential to stimulate competition and innovation in the drug
                market.
              </li>
              <li>Could lead to overall better public health outcomes.</li>
            </ul>
          </div>
        </Col>
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="cons">
            <h6 className="cons-title">
              Cons <FaRegThumbsDown className="icon" />
            </h6>
            <ul className="cons-list">
              <li>Potential backlash from pharmaceutical companies.</li>
              <li>Risk of reduced investment in research and development.</li>
              <li>
                Possible issues related to quality control and safety with
                imported drugs.
              </li>
              <li>Potential increase in taxpayer's burden.</li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );

  const impactOverview = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          domain={[0, "dataMax + 50"]}
          tickFormatter={(tick) => `$${tick}`}
        />
        <Tooltip formatter={(value) => `$${value}`} />
        <Legend />
        <Bar
          dataKey="currentPrice"
          name="Current Price"
          fill="#8884d8"
          label={renderCustomizedLabel}
        />
        <Bar
          dataKey="projectedPrice"
          name="Projected Price"
          fill="#82ca9d"
          label={renderCustomizedLabel}
        />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <CardComponent>
      <Title value={title} />
      <CardBodyComponent>
        <h5>The Proposal</h5>
        <section className="mb-4">
          <p className="text-sm">
            The proposed referendum seeks to implement the Affordable Medication
            Act, which includes several key features aimed at reducing
            prescription medication costs and increasing accessibility to
            essential drugs.
          </p>
        </section>

        <section className="mb-4">
          <h6 className="font-weight-bold mb-2">Key Features</h6>
          <ul className="text-sm pl-3">
            <li>
              Direct negotiation with pharmaceutical companies for fair drug
              prices.
            </li>
            <li>
              Permitting safe importation of prescription drugs from vetted
              international sources.
            </li>
            <li>
              Implementing a cap on out-of-pocket drug costs to protect
              consumers.
            </li>
            <li>
              Mandating transparency in drug pricing, eliminating hidden costs
              and fostering accountability.
            </li>
            <li>
              Regulating annual price increases on medications to prevent
              unjustified price hikes.
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h6 className="font-weight-bold mb-2">Voting Information</h6>
          <p className="text-sm mb-2">
            <strong>A "YES" vote</strong> supports the implementation of the
            Affordable Medication Act, aiming to reduce prescription medication
            costs and increase drug accessibility.
          </p>
          <p className="text-sm">
            <strong>A "NO" vote</strong> opposes the implementation of the
            Affordable Medication Act, maintaining the current state of
            prescription medication pricing and accessibility.
          </p>
        </section>

        <h5>Impact on Prescription Medication Costs</h5>

        <section className="mb-4">
          <p className="text-sm">
            The bar chart below compares the current and projected prices for
            four commonly used prescription medications, assuming the Affordable
            Medication Act is passed. These projections offer a snapshot of the
            potential financial benefits for consumers:
          </p>
          {impactOverview()}
        </section>

        {renderProsCons()}

        {isMobile ? (
          <section className="mb-4">
            <div className="header-with-icon mb-2">
              <h5 className="font-weight-bold">Impact Diagram</h5>
              <div className="tooltip-container">
                <FaSearchPlus
                  className="header-icon"
                  onClick={() => setIsOpen(true)}
                />
                <span className="tooltip-text">Click to enlarge</span>
              </div>
            </div>
            <p className="text-sm mb-2">
              The following diagram illustrates the potential impact of the new
              law on prescription medication costs and accessibility:
            </p>

            <div className="diagram-container">
              <button
                className="view-diagram-button"
                onClick={() => setIsOpen(true)}
              >
                View Diagram
              </button>
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={healthcarediagram}
                onCloseRequest={() => setIsOpen(false)}
              />
            )}
          </section>
        ) : (
          <section className="mb-4" style={{ marginBottom: "1.5rem" }}>
            <div
              className="header-with-icon mb-2"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
              }}
            >
              <h5 className="font-weight-bold" style={{ margin: 0 }}>
                Impact Diagram
              </h5>
              <div
                className="tooltip-container"
                style={{ position: "relative", display: "inline-block" }}
              >
                <FaSearchPlus
                  className="header-icon"
                  onClick={() => setIsOpen(true)}
                  style={{
                    marginLeft: "10px",
                    color: "#36454f",
                    cursor: "pointer",
                    transition: "color 0.2s",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
                <span
                  className="tooltip-text"
                  style={{
                    visibility: "hidden",
                    width: "120px",
                    backgroundColor: "black",
                    color: "#fff",
                    textAlign: "center",
                    borderRadius: "6px",
                    padding: "5px 0",
                    position: "absolute",
                    zIndex: 1,
                    bottom: "125%",
                    left: "50%",
                    marginLeft: "-60px",
                    opacity: 0,
                    transition: "opacity 0.3s",
                  }}
                >
                  Click to enlarge
                </span>
              </div>
            </div>
            <p
              className="text-sm mb-2"
              style={{ fontSize: "0.875rem", marginBottom: "0.5rem" }}
            >
              The following diagram illustrates the potential impact of the new
              law on prescription medication costs and accessibility:
            </p>

            <div
              className="diagram-container scrollable"
              onClick={() => setIsOpen(true)}
              style={{
                position: "relative",
                display: "inline-block",
                width: "100%",
                overflow: "hidden",
                height: "300px",
                cursor: "pointer",
              }}
            >
              <img
                src={healthcarediagram}
                alt=" Affordable Medication Act Impact Diagram"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  transition: "none",
                  width: "100%",
                  height: "auto",
                }}
              />
              <div
                className="overlay-text"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  opacity: 1,
                  fontSize: "14px",
                  pointerEvents: "none",
                }}
              >
                Click to enlarge
              </div>
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={healthcarediagram}
                onCloseRequest={() => setIsOpen(false)}
              />
            )}
          </section>
        )}
      </CardBodyComponent>
      <CardFooterComponent />
    </CardComponent>
  );
};

export default VoteHealthCare;
