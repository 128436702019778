// ReferendumForm.tsx

import React, { ReactNode, useContext, useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Container, Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { VoteContext } from './VoteContext';
import { AppPaths } from '../../AppPaths';

type VoteOption = "YES" | "NO";

interface ReferendumFormProps {
  referendum: string;
  voteDescription: ReactNode;
}

const ReferendumForm: React.FC<ReferendumFormProps> = ({ referendum, voteDescription }) => {
  const [vote, setVote] = useState<VoteOption | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();
  const voteContext = useContext(VoteContext);
  const existingVote = voteContext?.getVotes().find(vote => vote.referendum === referendum);

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        navigate(AppPaths.ReferendumPage);
      }, 1700);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [submitted, navigate]);

  const submitVote = (e: React.FormEvent) => {
    e.preventDefault();
    if (vote !== null && !existingVote) {
      voteContext?.addVote(referendum, vote);
      setSubmitted(true);
    }
  };

  return (
    <Container>
      {voteDescription}
      <Form onSubmit={submitVote}>
        {!existingVote && (<div><FormGroup tag="fieldset">
          <legend>Your Vote</legend>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="vote"
                value="YES"
                checked={vote === "YES"}
                onChange={() => setVote("YES")}
                disabled={existingVote !== undefined}
              />{' '}
              Yes
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="vote"
                value="NO"
                checked={vote === "NO"}
                onChange={() => setVote("NO")}
                disabled={existingVote !== undefined}
              />{' '}
              No
            </Label>
          </FormGroup>
        </FormGroup>
        <Button type="submit" disabled={existingVote !== undefined}>
          Submit Vote
        </Button></div>)}
        {submitted && (
          <Alert color="success" className="mt-3">
            Vote submitted! Redirecting...
          </Alert>
        )}
        {existingVote && !submitted && (
          <Alert color="info" className="mt-3">
            You've already voted on this referendum. Your vote was: {existingVote.vote}
          </Alert>
        )}
      </Form>
    </Container>
  );
};

export default ReferendumForm;
