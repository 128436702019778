import React from 'react';
import HealthcareReferendum from './HealthcareReferendum';

const HealthcareReferendumPage = () => (
  <div>
    <HealthcareReferendum />
  </div>
);

export default HealthcareReferendumPage;
