// TaxReferendum.tsx
import React from 'react';
import ReferendumForm from './ReferendumForm';
import VoteDescription from './VoteDescription';
import { Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardText, CardTitle } from 'reactstrap';

const TaxReferendum = () => {
  const paragraphs: string[] = [
    "Do you approve of an increase in the local sales tax rate by 0.5% for the next 10 years, with the estimated additional revenue of $50 million per year to be exclusively used for the maintenance and improvement of public infrastructure?",
  ];

  return (<ReferendumForm
    referendum="Local Sales Tax Increase for Public Infrastructure Improvement"
    voteDescription={
      <Card className="mb-3">
        <CardHeader tag="h5" >Proposition 137: Local Sales Tax Increase for Public Infrastructure Improvement</CardHeader>
        <CardBody>
          <p className="text-sm">This proposition, if approved, will increase the local sales tax rate by 0.5% for the next decade. The additional tax revenue, estimated at $50 million per year, will be specifically allocated towards the maintenance and improvement of public infrastructure in our community.</p>

          <h6 className="mt-2">Proposal:</h6>
          <ul className="text-sm">
            <li><strong>Enactment of a Sales Tax Increase:</strong> The local sales tax rate will be increased by 0.5% for a period of ten years, starting from January 1, 2025 and ending on December 31, 2034.</li>
            <li><strong>Dedication of Revenues:</strong> All additional revenues generated by this increase, estimated at $50 million per annum, will be expressly earmarked for the maintenance and improvement of public infrastructure.</li>
            <li><strong>Use of Funds:</strong> Funds collected from this tax increase will be used for projects such as: repairing roads and bridges; upgrading public transportation; improving parks, sidewalks, and other public spaces; enhancing utilities such as water and sewage systems.</li>
            <li><strong>Oversight and Transparency:</strong> An annual audit will be conducted to ensure that all revenues are spent as intended, and a report on the use of funds will be made publicly available.</li>
          </ul>

          <h6 className="mt-2">Voting:</h6>
          <p className="text-sm"><strong>A "YES" vote</strong> approves the sales tax increase of 0.5% for ten years, with the generated revenue to be used solely for public infrastructure maintenance and improvement.</p>
          <p className="text-sm"><strong>A "NO" vote</strong> opposes the sales tax increase of 0.5% for ten years, maintaining the current sales tax rate.</p>
        </CardBody>
        <CardFooter />
      </Card>
    }
  />)
};

export default TaxReferendum;
